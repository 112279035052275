import React from "react"
import { Box } from "theme-ui"
import { graphql } from "gatsby"

import ContentfulCarouselBlock from "../components/contentful-carousel-block"
import ContentfulMasonryPicturesBlock from "../components/contentful-masonry-pictures-block"
import ContentfulFooterBlock from "../components/contentful-footer-block"
import ContentfulFullscreenPictureBlock from "../components/contentful-fullscreen-picture-block"
import ContentfulHeadingBlock from "../components/contentful-heading-block"
import ContentfulIntroductionBlock from "../components/contentful-introduction-block"
import ContentfulSuggestionsBlock from "../components/contentful-suggestions-block"
import ContentfulProjectsPagination from "../components/contentful-projects-pagination"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { displayDesktopOnly, displayMobileOnly } from "../utils/sx"

const ProjectPagePage = ({ pageContext, data }) => {
  const projectPage = pageContext.node
  const { contentfulHomePage } = data
  const { projects } = contentfulHomePage

  const index = projects.findIndex((project) => {
    return project.id === projectPage.id
  })

  const previous = {
    index: (index === 0 ? projects.length - 1 : index - 1) + 1,
    project: index === 0 ? projects[projects.length - 1] : projects[index - 1],
  }

  const next = {
    index: (index === projects.length - 1 ? 0 : index + 1) + 1,
    project: index === projects.length - 1 ? projects[0] : projects[index + 1],
  }

  return (
    <>
      <SEO
        description={projectPage.metaDescription?.metaDescription}
        title={projectPage.metaTitle}
      />
      <Layout headerLabel={projectPage.headerLabel}>
        <ContentfulFullscreenPictureBlock picture={projectPage.picture1} />
        <ContentfulIntroductionBlock
          body={projectPage.introductionBody?.introductionBody}
          headline={projectPage.introductionHeadline?.introductionHeadline}
          tags={projectPage.introductionTags}
        />
        <ContentfulHeadingBlock text={projectPage.heading1?.heading1} />
        <Box sx={{ display: ["none", "block"] }}>
          {projectPage.carousel && projectPage.carousel.length > 0 && (
            <ContentfulCarouselBlock pictures={projectPage.carousel} />
          )}
        </Box>
        <Box sx={{ display: ["block", "none"] }}>
          {projectPage.carouselMobile &&
            projectPage.carouselMobile.length > 0 && (
              <ContentfulCarouselBlock
                identifier="carousel-mobile"
                pictures={projectPage.carouselMobile}
              />
            )}
        </Box>
        {projectPage.heading2 && (
          <ContentfulHeadingBlock text={projectPage.heading2?.heading2} />
        )}
        {projectPage.picture2 && (
          <ContentfulFullscreenPictureBlock picture={projectPage.picture2} />
        )}
        {projectPage.floatingPictures &&
          projectPage.floatingPictures.length > 0 && (
            <ContentfulMasonryPicturesBlock
              floatingPictures={projectPage.floatingPictures}
              text={projectPage.floatingHeading?.floatingHeading}
            />
          )}
        <Box sx={{ ...displayDesktopOnly }}>
          <ContentfulHeadingBlock
            level="h1"
            subtext={projectPage.feedbackAuthor}
            text={projectPage.feedbackBody?.feedbackBody}
          />
        </Box>
        <Box sx={{ ...displayMobileOnly }}>
          <ContentfulHeadingBlock
            subtext={projectPage.feedbackAuthor}
            text={projectPage.feedbackBody?.feedbackBody}
          />
        </Box>

        {projectPage.suggestedProjects &&
          projectPage.suggestedProjects.length > 0 && (
            <ContentfulSuggestionsBlock
              projects={projectPage.suggestedProjects}
              title={projectPage.suggestedTitle}
            />
          )}

        <ContentfulProjectsPagination previous={previous} next={next} />

        <ContentfulFooterBlock
          address={projectPage.footer?.address?.address}
          backgroundColor={projectPage.footer?.backgroundColor}
          copyright={projectPage.footer?.copyright?.copyright}
          socialLinksTitle={projectPage.footer?.socialLinksTitle}
          blogTitle={projectPage.footer?.blogTitle}
          newsletterJoinTitle={projectPage.footer?.newsletterJoinTitle}
          facebookLink={projectPage.footer?.facebookLink}
          gif={projectPage.footer?.gIf}
          gifDescription={projectPage.footer?.gifDescription}
          instagramLink={projectPage.footer?.instagramLink}
          linkedInLink={projectPage.footer?.linkedInLink}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    contentfulHomePage {
      projects {
        id
        slug
      }
    }
  }
`

export default ProjectPagePage
