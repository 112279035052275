import React, { useEffect, useState } from "react"
import { Box, Flex } from "theme-ui"

import { globalWindow } from "../utils/dom"
import HorizontalScroller from "./horizontal-scroller"

const getSlideWidth2 = () => {
  return globalWindow.innerWidth >= 832
    ? globalWindow.innerWidth - 300 - (globalWindow.innerWidth - 1280) / 2
    : globalWindow.innerWidth
}

const ContentfulCarouselBlock = ({
  identifier = 'carousel',
  pictures,
}) => {
  const [slideHeight, setSlideHeight] = useState(0)
  const [slideWidth, setSlideWidth] = useState(0)
  const [slideWidth2, setSlideWidth2] = useState(0)

  const getSlideHeight = () => {
    return globalWindow.innerHeight
  }

  const getSlideWidth = () => {
    return globalWindow.innerWidth >= 832
      ? globalWindow.innerWidth - 300 - (globalWindow.innerWidth - 1280) / 2
      : globalWindow.innerWidth
  }

  useEffect(() => {
    setSlideHeight(getSlideHeight())
    setSlideWidth(getSlideWidth())
    setSlideWidth2(getSlideWidth2())

    const onResize = () => {
      setSlideHeight(getSlideHeight())
      setSlideWidth(getSlideWidth())
      setSlideWidth2(getSlideWidth2())
    }

    globalWindow.addEventListener("resize", onResize)

    return () => {
      globalWindow.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <Flex
      sx={{
        bg: "white",
        flexDirection: "column",
        pl: `${globalWindow.innerWidth - slideWidth2}px`,
      }}
    >
      <HorizontalScroller
        identifier={identifier}
        showHeader={true}
        slideHeight={slideHeight}
        slideWidth={slideWidth}
        title=""
        total={pictures?.length || 0}
      >
        {pictures?.map((picture, index) => (
          <Box
            key={`picture-${index}`}
            sx={{
              alignItems: "center",
              display: "inline-flex",
              height: "100%",
              maxWidth: slideWidth,
              minWidth: slideWidth,
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                height: "60vh",
                maxHeight: "600px",
                paddingLeft: ["16px", "16px", "0px", "0px"],
                paddingRight: ["0px", "0px", "100px", "100px"],
              }}
            >
              <img
                alt=""
                src={picture?.resize?.src || ""}
                style={{
                  height: "100%",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </Box>
          </Box>
        ))}
      </HorizontalScroller>
    </Flex>
  )
}

export default ContentfulCarouselBlock
