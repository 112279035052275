import React from "react"
import { Box, Flex, Text } from "theme-ui"
import { InternalLink } from "./link"
import BlockContainer from "./block-container"
import ArrowRight from "../images/arrow-right.svg"
import ArrowLeft from "../images/arrow-left.svg"

const ContentfulProjectsPagination = ({ previous, next }) => (
  <BlockContainer>
    <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
      <Box sx={{
        order: [2, 1],
        width: ['50%', 'auto']
      }}>
        <InternalLink
          href={previous.project?.slug}
          style={{
            cursor: "pointer",
          }}
        >
            <Flex sx={{ alignItems: 'center' }}>
              <ArrowLeft width={15} />
              <Text variant="caption1">{previous.index.toString().padStart(2, '0')}</Text>
            </Flex>
        </InternalLink>
      </Box>
      <Box sx={{ 
        borderBottom: '1px solid black',
        borderTop: '1px solid black',
        margin: ['0 0 25px 0', '0 100px'],
        padding: ['20px 20px', '40px 80px'],
        textAlign: 'center',
        flex: ['auto', 1],
        order: [1, 2],
        width: ['100%', 'auto']
      }}>
        <Text variant="caption1">Looking for more case study goodness?</Text>
      </Box>
      <Box sx={{
        order: [2, 3],
        width: ['50%', 'auto']
      }}>
        <InternalLink
          href={next.project?.slug}
          style={{
            cursor: "pointer",
          }}
          >
            <Flex sx={{ 
              alignItems: 'center',
              justifyContent: ['flex-end', 'center']
            }}>
              <Text variant="caption1">{next.index.toString().padStart(2, '0')}</Text>
              <ArrowRight width={15} />
            </Flex>
        </InternalLink>
      </Box>
    </Flex>
  </BlockContainer>
)

export default ContentfulProjectsPagination
