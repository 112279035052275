/** @jsx jsx */

import React from "react"
import { jsx } from "theme-ui"
import { Box, Flex, Heading } from "theme-ui"

import BlockContainer from "./block-container"

import Masonry from 'react-masonry-component';
import Vimeo from '@u-wave/react-vimeo';

const masonryOptions = {
  transitionDuration: 0,
  columnWidth: '.masonry-column',
  gutter: '.masonry-gutter',
  itemSelector: '.masonry-item',
  percentPosition: true
};

const ContentfulChaoticPicturesBlock = ({
  floatingPictures,
  text,
}) => {
  return (
    <BlockContainer>
        {text && (
          <Box>
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Heading as="h3" sx={{ whiteSpace: "pre-wrap" }} variant="h3">
                {text}
              </Heading>
            </Flex>
          </Box>
        )}
        <Masonry
          className={'my-gallery-class'} // default ''
          options={masonryOptions} // default {}
          disableImagesLoaded={false} // default false
          updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
          enableResizableChildren={true}
        >
          <Box className="masonry-column" sx={{ width: ['100%', '30%'] }}></Box>
          <Box className="masonry-gutter" sx={{ width: [0, '5%'] }}></Box>
          {floatingPictures?.map(
            (floatingPicture, index) => {
              let colWidth = '30%'

              switch (floatingPicture.columnWidth) {
                case 'One Column':
                  colWidth = '30%'
                  break
                case 'Two Columns':
                  colWidth = '65%'
                  break
                case 'Three Columns':
                  colWidth = '100%'
                  break
                default:
                  colWidth = '30%'
                  break
              }                      

              const content = (
                <Box>
                  {floatingPicture.vimeoEmbed && (
                    <Vimeo
                      showTitle={false}
                      video={floatingPicture.vimeoEmbed?.vimeoEmbed}
                      width="640"
                      height="280"
                      responsive
                    />
                  )}
                  {floatingPicture.picture && (
                    <img
                      alt=""
                      src={floatingPicture.picture?.resize?.src || ""}
                      sx={{
                        height: "auto",
                        display: "block",
                        width: "100%",
                      }}
                    />
                  )}
                </Box>
              )
              return (<Box key={`box-${index}`} sx={{ marginBottom: '5%', width: ['100%', colWidth] }} className="masonry-item">{content}</Box>)
            },
          )}
        </Masonry>
    </BlockContainer>
  )
}

export default ContentfulChaoticPicturesBlock
